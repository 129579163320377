import React, { useState, ChangeEvent, useEffect } from 'react';
import { useItemsStore } from "../../../contexts/zustand/onboarding"
import { TypeEditField } from '../../../types/onboarding'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Grid, Box, Typography, TextField, Button } from '@mui/material';

const textFieldStyles = {
    marginRight: '5px',
    flex: 1,
    borderColor: '#9454A4',
    borderRadius: '5px',
    '&:hover': { border: 'none' }
};

const buttonStyles = {
    minWidth: 'auto',
    padding: '5px',
};

const iconSize = '2em';


export const TextComponentField = () => {
    return (
        <Grid sx={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '25px' }}>Propriedades do campo</Typography>
        </Grid>
    )
}

export const TextInputField = ({ refKey }: { refKey: string }) => {

    const { items, label, setLabel } = useItemsStore()
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const currentItem = items.find(item => item.key === refKey);

    useEffect(() => {
        if (!isInitialized && currentItem?.label) {
            setLabel(refKey, currentItem.label!);
            setIsInitialized(true);
        }
    }, [isInitialized, refKey, currentItem, setLabel]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (value.length === currentItem?.length_label!) {
            setError(`Você atingiu o limite máximo de ${currentItem?.length_label!} caracteres.`);
            setTimeout(() => {
                setError('');
            }, 3000);
        } else {
            setError('');
        }

        setLabel(refKey, value);
    };

    return (
        <Box sx={{ width: '100%', marginBottom: '10px' }}>
            <Typography sx={{ color: '#9454A4' }}>Nome do campo:</Typography>
            <TextField
                value={label[refKey] ?? currentItem?.label ?? ''}
                onChange={handleChange}
                sx={textFieldStyles}
                fullWidth
                variant="outlined"
                margin="normal"
                error={!!error}
                helperText={error}
                inputProps={{
                    maxLength: currentItem?.length_label,
                }}
            />
        </Box>
    )
}

export const SelectOptions = ({ refKey}: { refKey: string }) => {

    const { items, optionsValues, setOptionsValues } = useItemsStore();

    const [newOption, setNewOption] = useState<string>('');

    const currentItem = items.find(item => item.key === refKey);
    const initialOptions = currentItem?.options ?? [];

    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (initialOptions.length > 0 && !optionsValues[refKey]) {
            setOptionsValues(refKey, initialOptions);
        }
    }, [initialOptions, optionsValues, refKey, setOptionsValues]);

    const handleNewOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if (inputValue.length === currentItem?.length_label!) {
            setError(`Você atingiu o limite máximo de ${currentItem?.length_label!} caracteres.`);
            setTimeout(() => {
                setError('');
            }, 3000);
        } else {
            setError('');
        }

        setNewOption(inputValue);
    };

    const addOption = () => {
        const formattedOption = newOption.trim();

        if (formattedOption === "") {
            setError('Adicione uma opção')
            return
        }

        if (formattedOption && !(optionsValues[refKey] || []).includes(formattedOption)) {
            setOptionsValues(refKey, [...(optionsValues[refKey] || []), formattedOption]);
            setNewOption('');
            setError('');
        } else {
            setError('Opção já existe.');
        }
    };

    const removeOption = (index: number) => {
        const newOptions = optionsValues[refKey].filter((_, i: number) => i !== index);
        setOptionsValues(refKey, newOptions);
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const newOptions = [...(optionsValues[refKey] || [])];
        newOptions[index] = event.target.value;
        setOptionsValues(refKey, newOptions);
    };

    return (

        <Box
            sx={{
                width: '100%',
                marginBottom: '10px'
            }}
        >
            <Typography sx={{ color: '#9454A4', marginBottom: '5px' }}>Opções:</Typography>
            {(optionsValues[refKey] || initialOptions).map((option: string, index: number) => (
                <Box key={`option-${index}`} sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                    <TextField
                        value={option}
                        onChange={(e) => handleOptionChange(e, index)}
                        sx={{ flex: 1, marginRight: '10px' }}
                        size="small"
                        variant="outlined"
                        placeholder="Digite uma opção"
                    />

                    <Button onClick={() => removeOption(index)} sx={buttonStyles}>
                        <RemoveCircleOutlineIcon sx={{ color: 'red', fontSize: iconSize }} />
                    </Button>
                </Box>
            ))}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                <TextField
                    value={newOption}
                    onChange={handleNewOptionChange}
                    sx={{ flex: 1, marginRight: '10px' }}
                    size="small"
                    variant="outlined"
                    placeholder="Adicionar nova opção"
                    error={!!error}
                    helperText={error}
                    inputProps={{
                        maxLength: currentItem?.length_label,
                    }}
                />
                <Button onClick={addOption} sx={buttonStyles}>
                    <AddCircleOutlineIcon sx={{ color: 'green', fontSize: iconSize }} />
                </Button>
            </Box>
        </Box>
    );
};

export const RadioOptions = ({ refKey}: { refKey: string}) => {

    const { items, optionsValues, setOptionsValues } = useItemsStore();

    const [newOption, setNewOption] = useState<string>('');

    const currentItem = items.find(item => item.key === refKey);
    const initialOptions = currentItem?.options ?? [];

    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (initialOptions.length > 0 && !optionsValues[refKey]) {
            setOptionsValues(refKey, initialOptions);
        }
    }, [initialOptions, optionsValues, refKey, setOptionsValues]);

    const handleNewOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if (inputValue.length === currentItem?.length_label!) {
            setError(`Você atingiu o limite máximo de ${currentItem?.length_label!} caracteres.`);
            setTimeout(() => {
                setError('');
            }, 3000);
        } else {
            setError('');
        }

        setNewOption(inputValue);

    };

    const addOption = () => {
        const formattedOption = newOption.trim();

        if (formattedOption === "") {
            setError('Adicione uma opção')
            return
        }

        if (formattedOption && !(optionsValues[refKey] || []).includes(formattedOption)) {
            setOptionsValues(refKey, [...(optionsValues[refKey] || []), formattedOption]);
            setNewOption('');
            setError('');
        } else {
            setError('Opção já existe.')
        }
    }

    const removeOption = (index: number) => {
        const newOptions = optionsValues[refKey].filter((_, i: number) => i !== index);
        setOptionsValues(refKey, newOptions);
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const newOptions = [...(optionsValues[refKey] || [])];
        newOptions[index] = event.target.value;
        setOptionsValues(refKey, newOptions);
    };

    return (
        <Box
            sx={{
                width: '100%',
                marginBottom: '10px'
            }}
        >
            <Typography sx={{ color: '#9454A4', marginBottom: '5px' }}>Opções:</Typography>
            {(optionsValues[refKey] || initialOptions).map((option: string, index: number) => (
                <Box key={`option-${index}`} sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                    <TextField
                        value={option}
                        onChange={(e) => handleOptionChange(e, index)}
                        sx={{ flex: 1, marginRight: '10px' }}
                        size="small"
                        variant="outlined"
                        placeholder="Digite uma opção"
                    />

                    <Button onClick={() => removeOption(index)} sx={buttonStyles}>
                        <RemoveCircleOutlineIcon sx={{ color: 'red', fontSize: iconSize }} />
                    </Button>
                </Box>
            ))}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                <TextField
                    value={newOption}
                    onChange={handleNewOptionChange}
                    sx={{ flex: 1, marginRight: '10px' }}
                    size="small"
                    variant="outlined"
                    placeholder="Adicionar nova opção"
                    error={!!error}
                    helperText={error}
                    inputProps={{
                        maxLength: currentItem?.length_label,
                    }}
                />
                <Button onClick={addOption} sx={buttonStyles}>
                    <AddCircleOutlineIcon sx={{ color: 'green', fontSize: iconSize }} />
                </Button>
            </Box>
        </Box>
    );
};

export const CheckBoxOptions = ({ refKey }: { refKey: string }) => {

    const { items, optionsValues, setOptionsValues } = useItemsStore();

    const [newOption, setNewOption] = useState<string>('');

    const currentItem = items.find(item => item.key === refKey);
    const initialOptions = currentItem?.options ?? [];

    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (initialOptions.length > 0 && !optionsValues[refKey]) {
            setOptionsValues(refKey, initialOptions);
        }
    }, [initialOptions, optionsValues, refKey, setOptionsValues]);

    const handleNewOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if (inputValue.length === currentItem?.length_label!) {
            setError(`Você atingiu o limite máximo de ${currentItem?.length_label!} caracteres.`);
            setTimeout(() => {
                setError('');
            }, 3000);
        } else {
            setError('');
        }
        
        setNewOption(inputValue);
    };

    const addOption = () => {
        const formattedOption = newOption.trim();

        if (formattedOption === "") {
            setError('Adicione uma opção')
            return
        }
        
        if (formattedOption && !(optionsValues[refKey] || []).includes(formattedOption)) {
            setOptionsValues(refKey, [...(optionsValues[refKey] || []), formattedOption]);
            setNewOption('');
            setError('');
        } else {
            setError('Opção já existe.')
        }
    };

    const removeOption = (index: number) => {
        const newOptions = optionsValues[refKey].filter((_, i: number) => i !== index);
        setOptionsValues(refKey, newOptions);
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const newOptions = [...(optionsValues[refKey] || [])];
        newOptions[index] = event.target.value;
        setOptionsValues(refKey, newOptions);
    };

    return (
        <Box
            sx={{
                width: '100%',
                marginBottom: '10px'
            }}
        >
            <Typography sx={{ color: '#9454A4', marginBottom: '5px' }}>Opções:</Typography>
            {(optionsValues[refKey] || initialOptions).map((option: string, index: number) => (
                <Box key={`option-${index}`} sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                    <TextField
                        value={option}
                        onChange={(e) => handleOptionChange(e, index)}
                        sx={{ flex: 1, marginRight: '10px' }}
                        size="small"
                        variant="outlined"
                        placeholder="Digite uma opção"
                    />

                    <Button onClick={() => removeOption(index)} sx={buttonStyles}>
                        <RemoveCircleOutlineIcon sx={{ color: 'red', fontSize: iconSize }} />
                    </Button>
                </Box>
            ))}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                <TextField
                    value={newOption}
                    onChange={handleNewOptionChange}
                    sx={{ flex: 1, marginRight: '10px' }}
                    size="small"
                    variant="outlined"
                    placeholder="Adicionar nova opção"
                    error={!!error}
                    helperText={error}
                    inputProps={{
                        maxLength: currentItem?.length_label,
                    }}
                />
                <Button onClick={addOption} sx={buttonStyles}>
                    <AddCircleOutlineIcon sx={{ color: 'green', fontSize: iconSize }} />
                </Button>
            </Box>
        </Box>
    );
};

export const OptionRequired = ({ refKey }: { refKey: string }) => {

    const { items, valueRequired, setValueRequired } = useItemsStore();

    const currentItem = items.find(item => item.key === refKey);
    const initialChecked = currentItem?.required;

    useEffect(() => {
        if (refKey) {
            setValueRequired(refKey, initialChecked === true ? true : false);
        }
    }, [refKey, initialChecked, setValueRequired]);
    

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (refKey) {
            setValueRequired(refKey, e.target.checked);
        }
    };

    const isChecked = refKey ? (valueRequired[refKey] !== undefined ? valueRequired[refKey] : currentItem?.required) : false;

    return (

        <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
            label="Este campo é obrigatório:"
        />

    )
}

export const ButtonSavedValues = ({ open, setOpen, refKey, onSubmit }: TypeEditField) => {

    const handleSave = () => {
        onSubmit();
        setOpen(false);
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            justifyItems: 'center'
        }}>
            <Button
                sx={{
                    backgroundColor: '#9454A4', color: '#FFFF', '&:hover': {
                        backgroundColor: '#9454A4',
                        color: 'white',
                        border: 'none'
                    },
                    width: '20%', height: '60px', borderRadius: '4px', marginTop: '10%'
                }}
                onClick={handleSave}>
                SALVAR
            </Button>
        </Box>
    )
}
